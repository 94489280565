import React from "react"
import PropTypes from "prop-types"
import { Helmet } from '../../plugins/gatsby-plugin-react-i18next-99';
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, article }) => {
    const { pathname } = useLocation()
    const { site } = useStaticQuery(query)
    
    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        defaultImage,
    } = site.siteMetadata
    
    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
    }
    
    return (
        <Helmet
            title={seo.title}
            titleTemplate={titleTemplate}
        >            
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            
            {seo.url && <meta property="og:url" content={seo.url} />}            
            {(article ? true : null) && <meta property="og:type" content="article" />}            
            {seo.title && <meta property="og:title" content={seo.title} />}            
            {seo.description && (<meta property="og:description" content={seo.description} /> )}            
            {seo.image && <meta property="og:image" content={seo.image} />}            
        </Helmet>
    )
}

export default SEO
                
SEO.propTypes = {
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    article: PropTypes.bool,
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    title: null,
    description: null,
    image: null,
    article: false,
}
                
const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl: url
                defaultImage: image
            }
        }
    }
`
