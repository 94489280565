import * as React from "react";
import { graphql} from 'gatsby';
// import { Trans, Link, useI18next } from '../../plugins/gatsby-plugin-react-i18next-99';
import { Trans } from '../../plugins/gatsby-plugin-react-i18next-99';
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
// import { StaticImage } from 'gatsby-plugin-image';

import Typography from '@mui/material/Typography';

import Seo from "../components/Seo";

const Teaser = (props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const tablet = useMediaQuery(theme.breakpoints.down("lg"));
        // return (
        //     <Typography variant="teaser"><Trans>What can a Strategic</Trans><br /><Trans>Developer do for you ?</Trans></Typography>
        // )
    if (mobile) {
        return (
            <Typography variant="teaser"><Trans>What can a Strategic</Trans><br /><Trans>Developer do for you ?</Trans></Typography>
        )
    } else if (tablet) {
        return (
            <Typography variant="teaser"><Trans>What can a Strategic Developer do for you ?</Trans></Typography>
        )
    } else {
        return (
            <Typography variant="teaser"><Trans>What can a Strategic Developer do for you ?</Trans></Typography>
        )
    }
}

function Home (props) {
    return (
        <>
            <Seo />
            <section style={{
                display: 'flex',
                position: "relative",
                flexDirection: "column",
                width: "100%",
                // justifyContent: "center",
                // backgroundColor: "red",
                alignItems: "center",
            }}>
                <Teaser />
            </section>
        </>
    )
}

export default Home;


export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;